<template>
    <div class="content-table-cadidate">
        <div class="table-cadidate">
            <a-table
                size="middle"
                rowKey="id"
                :columns="columns"
                :dataSource="cadidates"
                bordered
                :pagination="{
                    current: page,
                    total: candidateData.total,
                    pageSize,
                    pageSizeOptions: ['10', '50', '100'],
                    showSizeChanger: true,
                    size: 'large',
                }"
                @change="handleChangePage"
            >
                <span slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="gender" slot-scope="text">{{
                    text == 'MALE' ? 'Nam' : text == 'FEMALE' ? 'Nữ' : 'Khác'
                }}</span>
                <span slot="idDateCreated" slot-scope="text, record">
                    {{ formatDate(record.idDateCreated) }}
                </span>
                <span slot="birthDay" slot-scope="text, record">
                    {{ formatDate(record.birthDay) }}
                </span>
                <span slot="history" slot-scope="value, record">
                    <a
                        class="ant-dropdown-link"
                        @click="handleViewHistory(record.id)"
                    >
                        Chi tiết
                    </a>
                </span>
                <span slot="status" slot-scope="value, record">
                    {{ getStatus(record) }}
                </span>
                <span slot="action" slot-scope="record">
                    <a class="ant-dropdown-link" @click="handleEdit(record.id)">
                        Sửa <a-icon type="edit" />
                    </a>
                </span>
            </a-table>
        </div>
    </div>
</template>

<script>
import { formatDate } from '../../utils/Date';
import { mapMutations } from 'vuex';
import { HISTORY_SET_ID_CANDIDATE } from '../../store/mutation-types';

const columns = [
    {
        title: 'STT',
        key: 'index',
        align: 'center',
        scopedSlots: { customRender: 'index' },
    },
    {
        title: 'Vị trí',
        dataIndex: 'staffType.name',
        align: 'center',
    },
    {
        title: 'Họ tên',
        dataIndex: 'fullName',
        align: 'center',
    },
    {
        title: 'Giới tính',
        dataIndex: 'gender',
        align: 'center',
        scopedSlots: { customRender: 'gender' },
    },
    {
        title: 'CMND',
        dataIndex: 'idNumber',
        align: 'center',
    },
    {
        title: 'Ngày cấp',
        dataIndex: 'idDateCreated',
        align: 'center',
        scopedSlots: { customRender: 'idDateCreated' },
    },
    {
        title: 'Nơi cấp',
        dataIndex: 'idCity',
        align: 'center',
    },
    {
        title: 'Ngày sinh',
        dataIndex: 'birthDay',
        align: 'center',
        scopedSlots: { customRender: 'birthDay' },
    },
    {
        title: 'SĐT',
        dataIndex: 'phone',
        align: 'center',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
        align: 'center',
    },
    {
        title: 'Khu vực',
        dataIndex: 'city.name',
        align: 'center',
    },
    // {
    //     title: 'Học vấn',
    //     dataIndex: 'literacy',
    //     align: 'center',
    // },
    {
        title: 'Nguồn TD',
        dataIndex: 'source.name',
        align: 'center',
    },
    {
        title: 'Trạng thái',
        dataIndex: 'isPassed',
        align: 'center',
        scopedSlots: { customRender: 'status' },
    },
    {
        title: 'Lịch sử PV',
        dataIndex: 'adminNote',
        scopedSlots: { customRender: 'history' },
    },
    {
        title: '',
        key: 'action',
        align: 'center',
        scopedSlots: { customRender: 'action' },
    },
];

export default {
    props: {
        page: Number,
        pageSize: Number,
        candidateData: Object,
    },

    data() {
        return {
            columns,
            formatDate,
        };
    },

    computed: {
        cadidates() {
            if (this.candidateData.candidates) {
                return this.candidateData.candidates;
            }
            return [];
        },
    },

    methods: {
        ...mapMutations('historyInterview', {
            setIDCandidate: HISTORY_SET_ID_CANDIDATE,
        }),
        handleChangePage(pagination) {
            this.$emit('change-page', pagination);
        },
        handleEdit(id) {
            this.$router.push({
                path: `/candidate/edit/${id}`,
            });
        },
        getStatus(record) {
            if (!record.isCompleted) return 'Đang phỏng vấn';
            if (record.isPassed) return 'Đạt';
            return 'Không đạt';
        },
        handleViewHistory(id) {
            this.setIDCandidate(id);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-table-cadidate {
    margin-top: 20px;

    .table-cadidate {
        margin-top: 15px;
    }
}
</style>
