<template>
    <div class="content-form">
        <a-form :form="form">
            <a-row :gutter="24">
                <!-- <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Thời gian">
                        <a-range-picker
                            v-decorator="['dateJoin']"
                            :placeholder="['Từ ngày', 'Đến ngày']"
                        />
                    </a-form-item>
                </a-col> -->

                <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Khu vực">
                        <a-select
                            show-search
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            v-decorator="['area', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="city in listCity"
                                :key="city.id"
                                :value="city.id"
                            >
                                {{ city.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Salon">
                        <a-select
                            show-search
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            v-decorator="['salon', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="salon in listSalon"
                                :key="salon.id"
                                :value="salon.id"
                            >
                                {{ salon.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Vị trí">
                        <a-select
                            show-search
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            v-decorator="['position', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="position in listPosition"
                                :key="position.id"
                                :value="position.id"
                            >
                                {{ position.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <!-- <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Salon">
                        <a-input v-decorator="['salon']" style="width: 100%" />
                    </a-form-item>
                </a-col> -->

                <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Giới tính">
                        <a-select
                            v-decorator="['gender', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option value="MALE">
                                Nam
                            </a-select-option>
                            <a-select-option value="FEMALE">
                                Nữ
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Nguồn TD">
                        <a-select
                            show-search
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            v-decorator="['source', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="source in listSource"
                                :key="source.id"
                                :value="source.id"
                            >
                                {{ source.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="4" :lg="6" :xxl="4">
                    <a-form-item label="Trạng thái">
                        <a-select
                            v-decorator="['status', { initialValue: 'ALL' }]"
                            style="width: 100%"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option value="NEW">
                                Đang phỏng vấn
                            </a-select-option>
                            <a-select-option :value="1">
                                Đạt
                            </a-select-option>
                            <a-select-option :value="0">
                                Không đạt
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="4"
                    :lg="6"
                    :xxl="4"
                    v-for="(round, index) in listRound"
                    :key="round.id"
                >
                    <a-form-item :label="round.name">
                        <!-- <a-select
                            v-decorator="[
                                `round${round.id}`,
                                { initialValue: 'ALL' },
                            ]"
                            style="width: 100%"
                        > -->
                        <a-select
                            v-model="roundSelected[index]"
                            style="width: 100%"
                            :disabled="checkDisableSelectRound(index)"
                        >
                            <a-select-option value="ALL">
                                Tất cả
                            </a-select-option>
                            <a-select-option
                                v-for="status in round.statuses"
                                :value="status.id"
                                :key="status.id"
                            >
                                {{ status.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button
                            type="primary"
                            @click="handleSubmit"
                            icon="search"
                        >
                            Xem kết quả
                        </a-button>
                    </a-form-item>
                </a-col>

                <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button @click="handleReset">Xóa tìm kiếm</a-button>
                    </a-form-item>
                </a-col>

                <a-col :span="4" class="form-filter_button">
                    <a-form-item>
                        <a-button
                            type="default"
                            icon="download"
                            @click="handleClickExport"
                        >
                            Xuất excel
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: this.$form.createForm(this, { name: 'FormFilter' }),
            roundSelected: {
                0: 'ALL',
                1: 'ALL',
                2: 'ALL',
            },
            currentFilter: {},
        };
    },

    computed: {
        ...mapState('source', {
            listSource: 'data',
        }),
        ...mapState('position', {
            listPosition: 'data',
        }),
        ...mapState('city', {
            listCity: 'data',
        }),
        ...mapState('round', {
            listRound: 'data',
        }),
        ...mapGetters('salon', {
            listSalon: 'salon',
        }),

        listStatus() {
            let result = [];
            this.listRound.map((item) => {
                result = [...result, ...item.statuses];
            });
            return result;
        },
    },

    methods: {
        checkDefaultValue(value) {
            return value && value != 'ALL';
        },

        getRoundFilter() {
            if (this.roundSelected[0] == 'ALL') return null;
            if (
                this.listRound[0].statuses.find(
                    (item) => item.id == this.roundSelected[0]
                ).passed == false ||
                this.roundSelected[1] == 'ALL'
            ) {
                return this.roundSelected[0];
            }
            if (
                this.listRound[1].statuses.find(
                    (item) => item.id == this.roundSelected[1]
                ).passed == false ||
                this.roundSelected[2] == 'ALL'
            ) {
                return this.roundSelected[1];
            }
            return this.roundSelected[2];
        },

        getValueFilter(values) {
            const { area, salon, gender, position, source, status } = values;
            let filterValue = {};
            if (this.checkDefaultValue(area)) {
                filterValue.city = {
                    id: area,
                };
            }
            if (this.checkDefaultValue(salon)) {
                filterValue.salonAssignee = salon;
            }
            if (this.checkDefaultValue(gender)) {
                filterValue.gender = gender;
            }
            if (this.checkDefaultValue(position)) {
                filterValue.staffType = {
                    id: position,
                };
            }
            if (this.checkDefaultValue(source)) {
                filterValue.source = {
                    id: source,
                };
            }

            if (status == 'NEW') {
                filterValue.isCompleted = false;
            } else if (status != 'ALL') {
                filterValue.isCompleted = true;
                filterValue.isPassed = !!status;
            }

            const statusFilterId = this.getRoundFilter();
            const statusFilter = this.listStatus.find(
                (status) => status.id === statusFilterId
            );
            if (statusFilter && statusFilter.passed) {
                let indexRound = this.listRound.findIndex((round) =>
                    round.statuses.some(
                        (status) => status.id === statusFilterId
                    )
                );
                if (indexRound < this.listRound.length - 1) {
                    filterValue.round = this.listRound[indexRound + 1];
                }
            }
            if (statusFilter && !filterValue.round) {
                filterValue.status = { ...statusFilter };
            }
            this.currentFilter = filterValue;
            return filterValue;
        },

        handleSubmit() {
            // e.preventDefault();
            this.form.validateFields((err, values) => {
                const filterValue = this.getValueFilter(values);
                this.$emit('click-filter', filterValue);
            });
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },

        handleReset() {
            this.form.resetFields();
            this.roundSelected = {
                0: 'ALL',
                1: 'ALL',
                2: 'ALL',
            };
            this.$emit('click-filter', {});
        },

        checkDisableSelectRound(roundIndex) {
            if (roundIndex == 0) {
                //first round
                return false;
            }

            if (
                this.roundSelected[0] == 'ALL' ||
                (this.listRound[0] &&
                    this.listRound[0].statuses.find(
                        (item) => item.id == this.roundSelected[0]
                    ).passed == false)
            ) {
                return true;
            }

            const prevRoundSelect = this.roundSelected[roundIndex - 1];
            const prevRoundData = this.listRound[roundIndex - 1] || {
                statuses: [],
            };
            const statusSelect = prevRoundData.statuses.find(
                (item) => item.id == prevRoundSelect
            );
            if (statusSelect) {
                return !statusSelect.passed;
            }

            return true;
        },

        handleClickExport() {
            this.$emit('click-export', this.currentFilter);
        },
    },
};
</script>

<style lang="scss">
.content-form {
    padding: 10px 15px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    /* margin-top: 20px; */

    .ant-form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
            min-width: 140px;
            text-align: left;
            line-height: unset;
        }

        .ant-form-item-control-wrapper {
            width: 100%;
        }
    }

    .form-filter_button {
        width: auto;
        padding-top: 22px;
    }
}
</style>
