var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-table-cadidate"},[_c('div',{staticClass:"table-cadidate"},[_c('a-table',{attrs:{"size":"middle","rowKey":"id","columns":_vm.columns,"dataSource":_vm.cadidates,"bordered":"","pagination":{
                current: _vm.page,
                total: _vm.candidateData.total,
                pageSize: _vm.pageSize,
                pageSizeOptions: ['10', '50', '100'],
                showSizeChanger: true,
                size: 'large',
            }},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"index",fn:function(text, record, index){return _c('span',{},[_vm._v("\n                "+_vm._s(index + 1)+"\n            ")])}},{key:"gender",fn:function(text){return _c('span',{},[_vm._v(_vm._s(text == 'MALE' ? 'Nam' : text == 'FEMALE' ? 'Nữ' : 'Khác'))])}},{key:"idDateCreated",fn:function(text, record){return _c('span',{},[_vm._v("\n                "+_vm._s(_vm.formatDate(record.idDateCreated))+"\n            ")])}},{key:"birthDay",fn:function(text, record){return _c('span',{},[_vm._v("\n                "+_vm._s(_vm.formatDate(record.birthDay))+"\n            ")])}},{key:"history",fn:function(value, record){return _c('span',{},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){return _vm.handleViewHistory(record.id)}}},[_vm._v("\n                    Chi tiết\n                ")])])}},{key:"status",fn:function(value, record){return _c('span',{},[_vm._v("\n                "+_vm._s(_vm.getStatus(record))+"\n            ")])}},{key:"action",fn:function(record){return _c('span',{},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){return _vm.handleEdit(record.id)}}},[_vm._v("\n                    Sửa "),_c('a-icon',{attrs:{"type":"edit"}})],1)])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }