class ExcelHelper {
    static downloadCSVFile(fileName, data) {
        const blob = new Blob([`\ufeff${data}`], {
            type: 'text/csv;charset=utf-8;',
        });
        ExcelHelper.downloadFile(blob, fileName);
    }

    static downloadFile(blob, fileName) {
        // IE 11 & Microsoft Edge
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            const data = URL.createObjectURL(blob);
            link.href = data;
            link.style = 'visibility:hidden';
            link.download = fileName;
            // In FF link must be added to DOM to be clicked
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
            }, 100);
        }
    }
}

export default ExcelHelper;
